/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { jsx, Box, Flex } from 'theme-ui';

import linkResolver from '../../utils/linkResolver';
import CircleArrow from '../../icons/CircleArrow';

const ImageCTA = ({ slice }) => {
  const {
    image,
    heading,
    align,
    action_label: actionLabel,
    action_url: actionURL,
  } = slice.primary;
  return (
    <section
      sx={{
        position: 'relative',
        a: { color: 'white' },
        my: 5,
        minHeight: 350,
      }}
    >
      {/* <p>{align}</p> */}
      <Img
        fluid={
          image.localFile ? image.localFile.childImageSharp.fluid : image.fluid
        }
        sx={{ minHeight: 350 }}
      />
      <Box
        sx={{
          bg: 'dimmed',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          top: 0,
          position: 'absolute',
          left: 0,
          width: '100%',
          height: '100%',
          p: [3, 0, 0],
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: heading.html }}
          sx={{
            h1: {
              fontSize: 5,
            },
            h2: {
              fontSize: 5,
            },
            h3: {
              fontSize: 3,
            },
            'h1, h2, h3': {
              fontWeight: 'normal',
              textAlign: align,
              mb: 4,
            },
          }}
        />
        <div sx={{ textAlign: align, fontStyle: 'italic' }}>
          {actionURL.type ? (
            <Link
              id="image-cta-button"
              to={linkResolver(actionURL)}
              sx={{
                ':hover': {
                  color: 'accent',
                  path: {
                    fill: 'accent',
                  },
                  ellipse: {
                    stroke: 'accent',
                  },
                },
              }}
            >
              <Flex sx={{ alignItems: 'center' }}>
                <Box>{actionLabel}</Box>
                <Box sx={{ ml: 3 }}>
                  <CircleArrow color1="#FFF" color2="#FFF" />
                </Box>
              </Flex>
            </Link>
          ) : (
            <a
              id="image-cta-button"
              href={actionURL.url}
              target={actionURL.target}
              sx={{
                ':hover': {
                  color: 'accent',
                  path: {
                    fill: 'accent',
                  },
                  ellipse: {
                    stroke: 'accent',
                  },
                },
              }}
            >
              <Flex sx={{ alignItems: 'center' }}>
                <Box>{actionLabel}</Box>
                <Box sx={{ ml: 3 }}>
                  <CircleArrow color1="#FFF" color2="#FFF" />
                </Box>
              </Flex>
            </a>
          )}
        </div>
      </Flex>
    </section>
  );
};

ImageCTA.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default ImageCTA;
