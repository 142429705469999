/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { jsx, Container, Box } from 'theme-ui';

import Form from '../Form';

const ContactForm = ({ slice }) => {
  const { heading, subheading, action_label: actionLabel } = slice.primary;
  return (
    <section sx={{ bg: 'bg.grey', py: 5, px: [3, 3, 0] }}>
      <Container
        variant="copyExtended"
        sx={{ display: 'flex', flexDirection: ['column', null, 'row'] }}
      >
        <Box sx={{ flex: 1, mr: [0, 0, 4] }}>
          <div
            dangerouslySetInnerHTML={{ __html: heading.html }}
            sx={{
              fontSize: 5,
              fontWeight: 'bold',
              mb: 4,
            }}
          />
          <p>{subheading}</p>
        </Box>
        <Box sx={{ width: ['100%', null, 400], bg: 'white', mt: [3, 3, 0] }}>
          <Form actionLabel={actionLabel} pageURL="/" />
        </Box>
      </Container>
    </section>
  );
};

ContactForm.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default ContactForm;
