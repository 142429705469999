import React from 'react';
import PropTypes from 'prop-types';

import { Box, Text } from 'theme-ui';

const Heading = ({ slice }) => {
  const { heading } = slice.primary;
  return (
    <Box as="section" mt={7} mb={5}>
      <Text
        sx={{ textAlign: 'center', color: 'brand' }}
        dangerouslySetInnerHTML={{ __html: heading.html }}
      />
    </Box>
  );
};

Heading.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default Heading;
