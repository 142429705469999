/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

import { jsx, Flex, Box, Text } from 'theme-ui';

const Feature = styled(Flex)`
  border-top: 1px solid lightgrey;
`;

const Spec = ({ slice }) => {
  const { items } = slice;
  // const { title } = slice.primary;
  return (
    <section>
      {/* <div>{title}</div> */}
      {items.map((item) => {
        const { label, image, description } = item;
        return (
          <div key={shortid.generate()}>
            <Flex
              sx={{
                flexDirection: ['column', null, 'row'],
                py: 3,
                borderTop: '1px solid lightgrey',
              }}
            >
              <Box
                sx={{
                  width: ['100%', null, '33%'],
                  mb: [3, null, 0],
                  flexShrink: 0,
                }}
              >
                <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'brand' }}>
                  {label}
                </Text>
              </Box>
              <Box
                sx={{
                  width: ['100%', null, '33%'],
                  fontSize: 1,
                  lineHeight: 1.6,
                  fontWeight: 'light',
                }}
                dangerouslySetInnerHTML={{ __html: description.html }}
              />
              {image.localFile && (
                <Img fixed={image.localFile.childImageSharp.fixed} />
              )}
            </Flex>
          </div>
        );
      })}
    </section>
  );
};

Spec.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default Spec;
