import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Button = styled('button')`
  border: none;
  outline: none;
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  // font-weight: bold;
`;

const Tab = ({ label, onClick, activeTab }) => {
  const onClickLabel = () => {
    onClick(label);
  };
  let className = 'tab-list-item';

  if (activeTab === label) {
    className += ' tab-list-active';
  }

  return (
    <Button type="button" className={className} onClick={onClickLabel}>
      {label}
    </Button>
  );
};

Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Tab;
