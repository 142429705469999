/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { jsx, Container, Flex, Box, Text } from 'theme-ui';
import { Link } from 'gatsby';

import linkResolver from '../../utils/linkResolver';
import CircleArrow from '../../icons/CircleArrow';

const renderAction = (actionLabel, actionURL) =>
  actionURL.type ? (
    <Link
      id="action-bar-button"
      to={linkResolver(actionURL)}
      sx={{
        color: 'white',
        fontWeight: 'bold',
        '&:hover': {
          color: 'accent',
          path: {
            fill: 'accent',
          },
          ellipse: {
            stroke: 'accent',
          },
        },
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <Box>{actionLabel || 'link'}</Box>
        <Box ml={[1, 1, 3]}>
          <CircleArrow />
        </Box>
      </Flex>
    </Link>
  ) : (
    <a
      id="action-bar-button"
      href={actionURL.url}
      target={actionURL.target}
      sx={{
        color: 'white',
        fontWeight: 'bold',
        ':hover': {
          color: 'accent',
          path: {
            fill: 'accent',
          },
          ellipse: {
            stroke: 'accent',
          },
        },
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <Box>{actionLabel || 'link'}</Box>
        <Box
          sx={{
            ml: [1, 1, 3],
            ellipse: {
              stroke: 'white',
            },
            path: {
              fill: 'white',
            },
          }}
        >
          <CircleArrow />
        </Box>
      </Flex>
    </a>
  );

const ActionBar = ({ slice }) => {
  const {
    label,
    action_label: actionLabel,
    action_url: actionURL,
  } = slice.primary;

  return (
    <section sx={{ bg: 'brand' }}>
      <Container
        variant="content"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 3,
          px: [3, 3, 0],
        }}
      >
        <Text as="p" sx={{ color: 'white' }}>
          {label}
        </Text>
        {renderAction(actionLabel, actionURL)}
      </Container>
    </section>
  );
};

ActionBar.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default ActionBar;
