/** @jsx jsx */

import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

import { jsx, Flex, Box, Text, Container } from 'theme-ui';

const Spacer = (props) => (
  <div
    {...props}
    sx={{
      minWidth: [32, null, 48],
      textAlign: 'center',
    }}
  />
);

const TextBox = styled(Box)`
  flex: 1;
  text-align: center;
`;

const ImageBox = styled(Box)`
  flex: 1;
  text-align: center;
`;

const Compare = ({ slice }) => {
  const { items } = slice;
  const { title_left: titleLeft, title_right: titleRight } = slice.primary;
  return (
    <Container variant="content" as="section" my={5}>
      <Flex
        sx={{
          color: 'brand',
          alignItems: 'center',
          justifyContent: 'center',
          my: 5,
          px: [3, null, 0],
        }}
      >
        <TextBox dangerouslySetInnerHTML={{ __html: titleLeft.html }} />
        <Spacer>vs</Spacer>
        <TextBox dangerouslySetInnerHTML={{ __html: titleRight.html }} />
      </Flex>
      {items.map((item) => {
        const {
          feature_left: featureLeft,
          feature_right: featureRight,
          image_left: imageLeft,
          image_right: imageRight,
          description_left: descriptionLeft,
          description_right: descriptionRight,
        } = item;
        return (
          <Box key={shortid.generate()} mt={6}>
            <Flex sx={{ px: [3, null, 0], justifyContent: 'center' }}>
              {imageLeft.localFile && (
                <Box
                  sx={{
                    flex: 1,
                    textAlign: 'center',
                    '.gatsby-image-wrapper': {
                      maxWidth: [140, null, 180],
                      maxHeight: [140, null, 180],
                    },
                  }}
                >
                  <Img fixed={imageLeft.localFile.childImageSharp.fixed} />
                </Box>
              )}
              <Spacer />
              {imageRight.localFile && (
                <Box
                  sx={{
                    flex: 1,
                    textAlign: 'center',
                    '.gatsby-image-wrapper': {
                      maxWidth: [140, null, 180],
                      maxHeight: [140, null, 180],
                    },
                  }}
                >
                  <Img fixed={imageRight.localFile.childImageSharp.fixed} />
                </Box>
              )}
            </Flex>
            <Flex sx={{ px: [3, null, 0], color: 'brand' }}>
              <TextBox
                my={3}
                dangerouslySetInnerHTML={{ __html: featureLeft.html }}
              />
              <Spacer />
              <TextBox
                my={3}
                dangerouslySetInnerHTML={{ __html: featureRight.html }}
              />
            </Flex>
            <Flex sx={{ px: [3, null, 0] }}>
              <TextBox>
                <Text
                  as="p"
                  sx={{
                    px: [0, null, 5],
                    lineHeight: 1.6,
                    fontWeight: 'light',
                    fontSize: [1, null, 'inherit'],
                  }}
                >
                  {descriptionLeft}
                </Text>
              </TextBox>
              <Spacer />
              <TextBox>
                <Text
                  as="p"
                  sx={{
                    px: [0, null, 5],
                    lineHeight: 1.6,
                    fontWeight: 'light',
                    fontSize: [1, null, 'inherit'],
                  }}
                >
                  {descriptionRight}
                </Text>
              </TextBox>
            </Flex>
          </Box>
        );
      })}
    </Container>
  );
};

Compare.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default Compare;
