import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

import { Box, Text } from 'theme-ui';

const ImageBox = styled(Box)`
  .gatsby-image-wrapper {
    height: 500px;
    img {
      object-fit: contain !important;
    }
  }
`;

const ImageCTA = ({ slice }) => {
  const { image, description } = slice.primary;
  return (
    <Box as="section" my={5}>
      {image.localFile && (
        <ImageBox>
          <Img fluid={image.localFile.childImageSharp.fluid} />
        </ImageBox>
      )}
      {description && <Text as="p">{description}</Text>}
    </Box>
  );
};

ImageCTA.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default ImageCTA;
