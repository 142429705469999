/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { jsx, Container, Flex, Box, Button } from 'theme-ui';
import { Link } from 'gatsby';

import linkResolver from '../../utils/linkResolver';

const Hero = ({ slice }) => {
  const {
    image,
    pre_heading: preHeading,
    heading,
    subheading,
    align,
    action_label: actionLabel,
    action_url: actionURL,
  } = slice.primary;
  return (
    <section sx={{ position: 'relative', minHeight: 350 }}>
      {image.localFile && (
        <Img
          fluid={
            image.localFile
              ? image.localFile.childImageSharp.fluid
              : image.fluid
          }
          sx={{ minHeight: 350 }}
        />
      )}
      <Box
        sx={{
          bg: 'dimmed',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      />

      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          top: 0,
          position: 'absolute',
          left: 0,
          width: '100%',
          height: '100%',
          p: [3, 0, 0],
        }}
      >
        <Container variant="copyExtended">
          {/* <p>{align}</p> */}
          {preHeading && <p sx={{ fontSize: 2, mb: 3 }}>{preHeading}</p>}
          {heading && (
            <div
              sx={{
                'h1, h2, h3, h4': { fontSize: [4, null, 6] },
                fontWeight: 'bold',
                mb: 4,
                textAlign: align,
              }}
              dangerouslySetInnerHTML={{ __html: heading.html }}
            />
          )}
          {subheading && <p sx={{ mt: 3, textAlign: align }}>{subheading}</p>}
          <Box sx={{ textAlign: align }}>
            <Button
              id="hero-button"
              as={Link}
              variant="fancyPrimary"
              sx={{ mt: 4, bg: 'accent', color: 'brand' }}
              to={actionURL.type ? linkResolver(actionURL) : actionURL.url}
            >
              {actionLabel}
            </Button>
          </Box>
        </Container>
      </Flex>
    </section>
  );
};

Hero.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default Hero;
