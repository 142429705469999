/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { jsx } from 'theme-ui';

import Spec from './Spec';
import Tabs from '../Tabs/Tabs';

const Specifications = ({ slice }) => {
  const { heading, specification } = slice.primary;
  return (
    <section sx={{ mt: 7, mb: 5, px: [3, null, 0] }}>
      <div
        sx={{ color: 'brand', mb: 5 }}
        dangerouslySetInnerHTML={{ __html: heading.html }}
      />
      <Tabs>
        {specification.document.data.body.map((item) => {
          const { title } = item.primary;
          return <Spec key={item.id} slice={item} label={title} />;
        })}
      </Tabs>
    </section>
  );
};

Specifications.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default Specifications;
