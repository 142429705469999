/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { jsx, Flex, Text, Container } from 'theme-ui';

const Columns = ({ slice }) => {
  const { items } = slice;
  return (
    <Container variant="content" as="section" my={5}>
      <Flex sx={{ flexDirection: ['column', null, 'row'] }}>
        {items.map((item) => {
          const { content, heading } = item;
          return (
            <div key={shortid.generate()} sx={{ mb: [5, null, 0] }}>
              <Text
                sx={{
                  textAlign: 'center',
                  px: 4,
                  mb: 3,
                  color: 'brand',
                }}
                dangerouslySetInnerHTML={{ __html: heading.html }}
              />
              <Text
                sx={{
                  textAlign: 'center',
                  px: 4,
                  lineHeight: 1.6,
                  fontWeight: 'light',
                }}
                dangerouslySetInnerHTML={{ __html: content.html }}
              />
            </div>
          );
        })}
      </Flex>
    </Container>
  );
};

Columns.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default Columns;
