import React from 'react';
import PropTypes from 'prop-types';

const CircleArrow = ({ color1 = '#ffffff', color2 = '#ffffff' }) => (
  <svg
    width="33px"
    height="34px"
    viewBox="0 0 33 34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 3</title>
    <g
      id="DONE-Home"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop-HD-Copy-3"
        transform="translate(-1249.000000, -670.000000)"
      >
        <g id="Group-3" transform="translate(1250.000000, 671.000000)">
          <g id="Group-10">
            <g id="Oval-2-Copy">
              <ellipse
                id="Oval"
                stroke={color1}
                cx="15.5"
                cy="16"
                rx="15.5"
                ry="16"
              ></ellipse>
              <path
                id="Line-2-Copy"
                d="M15,12 L24,16.5 L15,21 L15,17 L6,17 L6,16 L15,16 L15,12 Z"
                fill={color2}
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CircleArrow;
