import React from 'react';
import PropTypes from 'prop-types';

import { Box, Text } from '../shared/Elements';

const Paragraph = ({ slice }) => {
  const { content } = slice.primary;
  return (
    <Box as="section" my={9}>
      {content && (
        <Box width={2 / 3} mx="auto">
          <Text
            textAlign="center"
            dangerouslySetInnerHTML={{ __html: content.html }}
          />
        </Box>
      )}
    </Box>
  );
};

Paragraph.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default Paragraph;
