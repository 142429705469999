/** @jsx jsx */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { jsx, Text, Button, Spinner } from 'theme-ui';
import { InputField, TextareaField } from './fields';

const ContactFormSchema = Yup.object().shape({
  first: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  last: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  comment: Yup.string()
    .min(1, 'Too Short!')
    .max(1000, 'Too Long!')
    .required('Required'),
});

const Form = ({ actionLabel, pageURL }) => {
  const [working, setWorking] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  };

  return (
    <div sx={{ p: 4 }}>
      {success ? (
        <>
          <Text variant="contact.name" sx={{ mt: 5 }}>
            Your message has been sent, we will be in contact shortly.
          </Text>
        </>
      ) : (
        <Formik
          initialValues={{
            first: '',
            last: '',
            email: '',
            phone: '',
            comment: '',
          }}
          validationSchema={ContactFormSchema}
          onSubmit={async (values) => {
            setWorking(true);
            fetch('/', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: encode({
                'form-name': 'contact',
                ...values,
                pageURL,
              }),
            })
              .then(() => {
                setWorking(false);
                setSuccess(true);
              })
              .catch((error) => {
                setWorking(false);
                setErrorMessage(`We're sorry but something went wrong`);
              });
          }}
        >
          {({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              name="contact"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              sx={{ width: '100%' }}
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="page" />

              <Field
                id="first"
                name="first"
                type="text"
                placeholder="First Name"
                component={InputField}
                sx={{ mt: 3 }}
              />
              <Field
                id="last"
                name="last"
                type="text"
                placeholder="Last Name"
                component={InputField}
                sx={{ mt: 3 }}
              />
              <Field
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                component={InputField}
                sx={{ mt: 3 }}
              />
              <Field
                id="phone"
                name="phone"
                type="text"
                placeholder="Phone Number"
                component={InputField}
                sx={{ mt: 3 }}
              />
              <Field
                id="comment"
                name="comment"
                type="text"
                placeholder="Comment"
                rows={2}
                component={TextareaField}
                sx={{ mt: 3 }}
              />
              {errorMessage && (
                <p sx={{ fontSize: 1, color: 'error', mt: 3 }}>
                  {errorMessage}
                </p>
              )}
              {working ? (
                <Spinner variant="styles.spinner" />
              ) : (
                <Button
                  type="submit"
                  variant="fancyPrimary"
                  disabled={working}
                  sx={{
                    width: '100%',
                    mt: 4,
                    bg: 'brand',
                    color: 'white',
                    ':before': { bg: 'brand' },
                  }}
                >
                  {actionLabel}
                </Button>
              )}
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

Form.propTypes = {
  actionLabel: PropTypes.string.isRequired,
  pageURL: PropTypes.string.isRequired,
};

export default Form;
