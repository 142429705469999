import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Container, Box, Text } from 'theme-ui';

const VideoWrapper = styled(Box)`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Video = ({ slice }) => {
  const {
    video_url: videoURL,
    heading,
    action_label: actionLabel,
  } = slice.primary;
  return (
    <Container as="section" variant="content" my={5}>
      {heading && (
        <Text
          sx={{
            textAlign: 'center',
            px: 4,
            mb: 5,
            color: 'brand',
          }}
          dangerouslySetInnerHTML={{ __html: heading.html }}
        />
      )}
      <VideoWrapper dangerouslySetInnerHTML={{ __html: videoURL.html }} />
    </Container>
  );
};

Video.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default Video;
