/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { jsx, Container, Flex, Box, Text, Button } from 'theme-ui';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import shortid from 'shortid';
import linkResolver from '../../utils/linkResolver';

const Ribbon = ({ children }) => (
  <Box
    sx={{
      position: 'absolute',
      top: -2,
      left: -2,
      zIndex: 1,
      borderRadius: 'small',
    }}
  >
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        color: 'white',
        fontSize: 0,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        borderRadius: '3px',
        overflow: 'hidden',
        minHeight: 40,

        ':before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: '-5px',
          height: '66%',
          width: '100%',
          borderRadius: '3px',
          background:
            'linear-gradient(90deg, #45166A -1.91%, #83157B 44.71%, #BC158A 106.54%)',
          transform: 'skew(-18deg, 0deg)',
        },
        ':after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: '-5px',
          height: '66%',
          width: '100%',
          borderRadius: '3px',
          background:
            'linear-gradient(90deg, #45166A -1.91%, #83157B 44.71%, #BC158A 106.54%)',
          transform: 'skew(18deg, 0deg)',
        },
      }}
    >
      <p sx={{ mx: 3, my: 2, zIndex: 1, letterSpacing: '.5px' }}>{children}</p>
    </Flex>
  </Box>
);

Ribbon.propTypes = {
  children: PropTypes.node.isRequired,
};

const ProductBox = ({
  id,
  title,
  subtitle,
  image,
  price,
  ribbon,
  actionLabel,
  actionURL,
}) => (
  <Box
    sx={{
      position: 'relative',
      bg: 'bg.grey',
      textAlign: 'center',
      px: 3,
      py: 4,
      mb: [5, null, 0],
      width: 350,
    }}
  >
    <Img fixed={image.localFile.childImageSharp.fixed} />
    <Text
      sx={{ color: 'brand', mb: 3 }}
      dangerouslySetInnerHTML={{ __html: title.html }}
    />
    <Text sx={{ mb: 4, fontWeight: 'light' }}>{subtitle}</Text>
    <Text sx={{ mb: 3, fontWeight: 'bold', color: 'brand' }}>{price}</Text>
    <Ribbon>{ribbon}</Ribbon>
    <Button
      id={id}
      sx={{ bg: 'accent', color: 'brand' }}
      variant="fancyPrimary"
      as={Link}
      to={linkResolver(actionURL)}
    >
      {actionLabel}
    </Button>
  </Box>
);

ProductBox.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  price: PropTypes.string.isRequired,
  ribbon: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  actionURL: PropTypes.object.isRequired,
};

const ProductGrid = ({ slice }) => {
  const { items } = slice;
  const {
    heading,
    subheading,
    text_align: textAlign = 'center',
  } = slice.primary;

  const products = items.map((i) => i.product.document.data);

  return (
    <Container as="section" variant="content" sx={{ mt: 5, px: [3, 0, 0] }}>
      {heading && (
        <Box width={2 / 3} mx="auto">
          <Text
            sx={{
              textAlign,
              color: 'brand',
            }}
            dangerouslySetInnerHTML={{ __html: heading.html }}
          />
        </Box>
      )}
      {subheading && (
        <Box width={2 / 3} mx="auto">
          <Text
            sx={{
              mt: 3,
              mb: 5,
              textAlign,
              fontWeight: 'light',
              p: {
                my: 4,
              },
            }}
          >
            {subheading}
          </Text>
        </Box>
      )}
      <Flex
        sx={{
          flexDirection: ['column', null, 'row'],
          justifyContent: 'space-around',
        }}
      >
        {products.map(
          (
            {
              title,
              subtitle,
              image,
              price,
              ribbon,
              action_label: actionLabel,
              action_url: actionURL,
            },
            i
          ) => (
            <ProductBox
              key={shortid.generate()}
              id={`product-box-button-${i + 1}`}
              title={title}
              subtitle={subtitle}
              image={image}
              price={price}
              ribbon={ribbon}
              actionLabel={actionLabel}
              actionURL={actionURL}
            />
          )
        )}
      </Flex>
    </Container>
  );
};

ProductGrid.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default ProductGrid;
