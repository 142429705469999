import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import Img from 'gatsby-image';

import { Flex, Text } from 'theme-ui';

const ImageStrip = ({ slice }) => {
  const { items } = slice;
  return (
    <Flex
      as="section"
      sx={{
        px: [3, null, 0],
        my: 5,
        justifyContent: 'space-around',
        flexWrap: 'wrap',
      }}
    >
      {items.map((item) => {
        const { image, text } = item;
        return (
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              width: ['40%', null, 'auto'],
              textAlign: 'center',
              mb: [4, null, 'auto'],
            }}
            key={shortid.generate()}
          >
            <Img fixed={image.localFile.childImageSharp.fixed} />
            <Text mt={3} as="p" sx={{ fontSize: '10px', fontWeight: 'bold' }}>
              {text}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};

ImageStrip.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default ImageStrip;
