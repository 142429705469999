import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { Text } from 'theme-ui';

import { Box } from '../shared/Elements';

const Container = styled(Box)`
  line-height: 1.6;
  p: {
    margin-bottom: ${themeGet('space.4')};
  }
  h1, h2, h3, h4, h5, h6: {
    margin-bottom: ${themeGet('space.3')};
  }
  h2: {
    font-size: ${themeGet('fontSizes.5')};
  }
  h3: {
    font-size: ${themeGet('fontSizes.4')};
  }
  .block-img {
    margin: 32px -64px;
    img {
      width: 100%;
    }
  }
`;

const TextBlock = ({ slice }) => {
  const { heading, content, text_align: textAlign = 'center' } = slice.primary;
  return (
    <Container as="section" sx={{ px: [3, 0, 0] }}>
      {heading && (
        <Box width={2 / 3} mx="auto">
          <Text
            sx={{
              mt: 7,
              mb: 5,
              textAlign,
              color: 'brand',
            }}
            dangerouslySetInnerHTML={{ __html: heading.html }}
          />
        </Box>
      )}
      {content && (
        <Box width={2 / 3} mx="auto">
          <Text
            sx={{
              my: 5,
              textAlign,
              fontWeight: 'light',
              p: {
                my: 4,
              },
            }}
            dangerouslySetInnerHTML={{ __html: content.html }}
          />
        </Box>
      )}
    </Container>
  );
};

TextBlock.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default TextBlock;
