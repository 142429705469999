/** @jsx jsx */
import { jsx, Container, Flex, Box } from 'theme-ui';

import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Link } from 'gatsby';
import ArrowCircleDown from '../../icons/ArrowCircleDown';
import linkResolver from '../../utils/linkResolver';

const renderList = (items, basePath) => (
  <ul sx={{ listStyle: 'none' }}>
    {items.map((item) => {
      const { uid = '' } = item.category;
      const { color, name } = item.category.document.data;

      return (
        <li
          key={shortid.generate()}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 4,
            '&:last-of-type': { mb: 0 },
          }}
        >
          <div
            sx={{
              width: 20,
              height: 20,
              borderRadius: 'default',
              bg: color,
            }}
          />
          <Link
            to={`${basePath}/${uid}`}
            sx={{
              ml: 3,
              fontSize: 2,
              color: 'menu.lightGrey',
              '&:hover': {
                color: 'accent',
              },
            }}
          >
            {name}
          </Link>
        </li>
      );
    })}
  </ul>
);

const BEM = ({ slice, basePath }) => {
  const {
    heading,
    subheading,
    info_title: infoTitle,
    what_title: whatTitle,
    how_title: howTitle,
    info_content: infoContent,
  } = slice.primary;

  const ref = createRef();

  const handleClick = () => {
    if (ref.current.style.maxHeight !== '0px') {
      ref.current.style.maxHeight = '0px';
    } else {
      ref.current.style.maxHeight = `${ref.current.scrollHeight}px`;
    }
  };

  const what = slice.items.filter(({ group }) => group === 'What Board Does');
  const how = slice.items.filter(({ group }) => group !== 'What Board Does');
  return (
    <section sx={{ bg: 'bg.grey', display: ['none', null, 'block'] }}>
      <Container variant="content">
        <Box
          onClick={handleClick}
          sx={{
            py: 4,
            color: 'accent',
            '&:hover': {
              color: 'accentHover',
              cursor: 'pointer',
            },
          }}
        >
          <Flex>
            <h1 sx={{ fontSize: 4, fontWeight: 'bold', mr: 3 }}>
              {heading.text}
            </h1>
            {/* <ArrowCircleDown /> */}
          </Flex>
          <p sx={{ fontSize: 0 }}>{subheading}</p>
        </Box>
        <div
          ref={ref}
          style={{
            maxHeight: 0,
          }}
          sx={{
            overflow: 'hidden',
            transition: 'max-height 0.2s ease-out',
          }}
        >
          <Flex sx={{ pt: 4, pb: 5 }}>
            <div sx={{ width: 300 }}>
              <p sx={{ mb: 4, fontWeight: 'bold', color: 'accent' }}>
                {whatTitle}
              </p>
              {renderList(what, basePath)}
            </div>
            <div sx={{ width: 300 }}>
              <p sx={{ mb: 4, fontWeight: 'bold', color: 'accent' }}>
                {howTitle}
              </p>
              {renderList(how, basePath)}
            </div>
            <div sx={{ flex: 1 }}>
              <p sx={{ mb: 4, fontWeight: 'bold', color: 'accent' }}>
                {infoTitle}
              </p>
              <div
                sx={{
                  fontSize: 2,
                  color: 'menu.lightGrey',
                  lineHeight: 1.8,
                  p: { mb: 3 },
                }}
                dangerouslySetInnerHTML={{ __html: infoContent.html }}
              />
            </div>
          </Flex>
        </div>
      </Container>
    </section>
  );
};

BEM.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default BEM;
