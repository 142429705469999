/** @jsx jsx */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

import { jsx, Flex, Box } from 'theme-ui';
import Tab from './Tab';

const TabsList = styled('div')`
  padding: 0;
  margin-bottom: 16px;

  & .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    border-bottom: 2px solid transparent;
  }

  & .tab-list-active {
    background-color: transparent;
    color: ${themeGet('colors.brand')};
    border-bottom: 2px solid ${themeGet('colors.brand')};
  }
`;

const TabsContent = styled('div')`
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="tabs">
      <Flex sx={{ justifyContent: 'center' }}>
        <div
          className="tab-list"
          sx={{
            p: 0,
            mb: 3,

            '& .tab-list-item': {
              display: 'inline-block',
              listStyle: 'none',
              mb: '-1px',
              padding: '0.5rem 0.75rem',
              borderBottom: '2px solid transparent',
              fontSize: 1,
            },

            '& .tab-list-active': {
              bg: 'transparent',
              color: 'brand',
              borderBottom: ({ colors }) => `2px solid ${colors.brand}`,
              fontWeight: 'bold',
            },
          }}
        >
          {children.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </div>
      </Flex>
      <div className="tab-content">
        {children.map((child) => {
          const { label } = child.props;
          return (
            <TabsContent key={label} active={child.props.label === activeTab}>
              {child}
            </TabsContent>
          );
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.array.isRequired,
};

export default Tabs;
