/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { jsx, Container, Button, Text } from 'theme-ui';
import { Link } from 'gatsby';

import linkResolver from '../../utils/linkResolver';

const CTA = ({ slice }) => {
  const {
    heading,
    subheading,
    action_label: actionLabel,
    action_url: actionURL,
  } = slice.primary;
  return (
    <Container
      as="section"
      variant="content"
      sx={{
        px: [3, 3, 0],
        textAlign: 'center',
        my: 6,
        h1: { fontSize: 6 },
        h2: { fontSize: 5 },
        h3: { fontSize: 4 },
        h4: { fontSize: 3 },
      }}
    >
      <Text
        sx={{ mb: 4, textAlign: 'center', color: 'brand' }}
        dangerouslySetInnerHTML={{ __html: heading.html }}
      />
      <p
        sx={{
          mb: 4,
          maxWidth: 550,
          mx: 'auto',
          fontWeight: 'light',
        }}
      >
        {subheading}
      </p>
      {actionURL.type ? (
        <Button
          id="cta-button"
          variant="fancyPrimary"
          as={Link}
          to={linkResolver(actionURL)}
          sx={{
            bg: 'brand',
            color: 'white',
            ':before': { bg: 'brand' },
          }}
        >
          {actionLabel || 'link'}
        </Button>
      ) : (
        <Button
          id="cta-button"
          variant="fancyPrimary"
          as="a"
          href={actionURL.url}
          target={actionURL.target}
          sx={{
            bg: 'brand',
            color: 'white',
            ':before': { bg: 'brand' },
          }}
        >
          {actionLabel || 'link'}
        </Button>
      )}
    </Container>
  );
};

CTA.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default CTA;
