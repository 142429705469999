/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Heading,
  Paragraph,
  TextBlock,
  Image,
  ImageStrip,
  Columns,
  Compare,
  Specifications,
  Spec,
  Video,
  BEM,
  Hero,
  ActionBar,
  CTA,
  ImageCTA,
  ProductGrid,
  ContactForm,
} from './slices';

const SlicesRenderer = ({ slices, ...props }) => {
  if (!slices || slices.length === 0) return null;

  return slices.map((slice) => {
    switch (slice.slice_type) {
      case 'hero':
        return <Hero key={slice.id} slice={slice} />;
      case 'action_bar':
        return <ActionBar key={slice.id} slice={slice} />;
      case 'heading':
        return <Heading key={slice.id} slice={slice} />;
      case 'paragraph':
        return <Paragraph key={slice.id} slice={slice} />;
      case 'text_block':
        return <TextBlock key={slice.id} slice={slice} />;
      case 'image':
        return <Image key={slice.id} slice={slice} />;
      case 'image_strip':
        return <ImageStrip key={slice.id} slice={slice} />;
      case 'columns':
        return <Columns key={slice.id} slice={slice} />;
      case 'compare':
        return <Compare key={slice.id} slice={slice} />;
      case 'specifications':
        return <Specifications key={slice.id} slice={slice} />;
      case 'spec':
        return <Spec key={slice.id} slice={slice} />;
      case 'video':
        return <Video key={slice.id} slice={slice} />;
      case 'bem':
        return <BEM key={slice.id} slice={slice} {...props} />;
      case 'cta':
        return <CTA key={slice.id} slice={slice} {...props} />;
      case 'image_cta':
        return <ImageCTA key={slice.id} slice={slice} {...props} />;
      case 'product_grid':
        return <ProductGrid key={slice.id} slice={slice} {...props} />;
      case 'contact_form':
        return <ContactForm key={slice.id} slice={slice} />;

      default:
        return null;
    }
  });
};

SlicesRenderer.propTypes = {
  slices: PropTypes.array.isRequired,
};

export default SlicesRenderer;
